<template>
  <div class="live">
    <videoPlayer
      v-if="isLivePaly"
      :source="source"
      @close="handleVideoClose"
    ></videoPlayer>
    <el-row>
      <el-col
        :span="8"
        v-for="(item, index) in cameraObj"
        :key="index"
        class="panel"
        :class="{
          'is-online': isOnline(item),
          'is-live': isLive(item),
        }"
      >
        <div @click="isLive(item) && getLiveData(item.liveVideoId)">
          <div class="panel__top">
            <span class="panel__num">{{ index + 1 }}</span>
            <span class="panel__text">{{ item.deviceName }}</span>
            <div class="panel__rightBox">
              <img
                class="camera"
                src="@/assets/images/live_broadcast/icon_camera.png"
                alt=""
              />
              <div class="circleInfo">
                <span class="panel__circle"></span>
                <span class="panel__stateText">
                  {{ stateText(item) }}
                </span>
              </div>
            </div>
          </div>
          <div class="panel__content">
            <div class="paragraph">
              <p>场地：{{ item.spaceName || "暂无" }}</p>
              <p>位置：{{ item.location || "暂无安装位置信息" }}</p>
              <div v-if="item.display === 'Y'" class="tips">场地首页显示</div>
            </div>
            <div class="rightBg">
              <img src="@/assets/images/live_broadcast/live_bg.png" alt="" />
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import apis from "@/apis";
import { mapState } from "vuex";
import videoPlayer from "./components/videoPlayer";

export default {
  components: {
    videoPlayer,
  },
  data() {
    return {
      cameraObj: [],
      isLivePaly: false,
      source: "",
    };
  },
  computed: {
    ...mapState(["Language", "TOKEN", "venueId"]),
  },
  //data中的数据变化
  watch: {},
  //方法集合
  methods: {
    isOnline(item) {
      return !!(item.online === "0");
    },
    isLive(item) {
      return !!(item.online === "0" && item.liveVideoId);
    },
    stateText(item) {
      let result = "";
      if (item.online === "1") {
        result = "离线";
      } else if (item.liveVideoId) {
        result = "直播中";
      } else {
        result = "在线";
      }
      return result;
    },
    getCamera() {
      this.$http
        .get(`${apis.getLiveCameraList}?venueId=${this.venueId}`)
        .then((res) => {
          console.log(res);
          if (res.data.code === 0) {
            this.cameraObj = res.data.data;
          } else {
            this.$message.info(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    getLiveData(liveVideoId) {
      console.log(liveVideoId, this.TOKEN);
      this.play(
        `${apis.getLivePreview}?liveVideoId=${liveVideoId}&token=${this.TOKEN}`,
      );
    },
    play(source) {
      this.source = source;
      this.isLivePaly = true;
    },
    handleVideoClose() {
      this.isLivePaly = false;
    },
  },
  //创建完成
  created() {
    // this.getCamera();
  },
  // FIXME: 设置keeplive无效，使用beforeRouteEnter强制调用接口解决
  beforeRouteEnter(to, from, next) {
    console.log("我被调用了");
    next((vm) => {
      // 通过 `vm` 访问组件实例
      console.log(vm);
      vm.getCamera();
    });
  },
};
</script>
<style lang='scss' scoped>
.live {
  padding: 15px 20px;
}

.panel__top {
  display: flex;
  justify-content: space-between;
}
.panel__num {
  width: 60px;
  height: 56px;
  margin: -1px 0 0 18px;
  line-height: 56px;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  background-color: #439bff;
}
.panel__text {
  width: 140px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
  margin-top: 5px;
  height: 44px;
}
.panel__rightBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 22px 0 0;
  img.camera {
    width: 41px;
    height: 40px;
  }
  .circleInfo {
    padding-left: 15px;
    font-size: 12px;
  }
}
.panel__content {
  margin: 22px 0 0 18px;
  .paragraph {
    p {
      color: #4f4f4f;
      font-size: 14px;
      font-weight: bold;
      line-height: 37px;
    }
    .tips {
      color: #999999;
      font-size: 12px;
      margin-top: 25px;
    }
  }
  .rightBg {
    img {
      width: 160px;
      height: 147px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
.panel {
  width: 362px;
  height: 225px;
  margin: 0 20px 20px 0;
  border: 1px solid #f3f5f7;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  position: relative;
  -webkit-box-shadow: 0px 2px 6px 0px rgb(115 115 115 / 50%);
  box-shadow: 0px 2px 6px 0px rgb(115 115 115 / 50%);
  &.is-live {
    cursor: pointer;
  }
}
.panel__circle {
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #e02020;
  &:after {
    content: "";
    display: block;
    position: relative;
    top: 1px;
    left: 1px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #e02020;
  }
}
.is-online .panel__circle {
  border-color: #6dd400;
  &:after {
    background-color: #6dd400 !important;
  }
}
.panel__stateText {
  padding-left: 5px;
  color: #e02020;
}
.is-online .panel__stateText {
  color: #6dd400;
}
</style>
